<template>
  <div class="recommend-tabbar">
    <div class="recommend-class"
         v-for="(item, index) in tabList"
         :key="index"
         @click="$emit('update:curIndex',index)">
      <div class="recommend-text "
           :class="{'active' : curIndex === index }">

        <slot :item="item">
          <div class="class-title">{{item}}</div>
        </slot>

      </div>
      <div class="split-line"
           v-show="index !== (tabList.length-1)">/</div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    tabList: {
      type: Array,
      default: () => ['场馆首页', '文化风俗', '生态旅游', '地方馆']
    },
    curIndex: {
      type: [String, Number],
      default: () => 0
    }
  },
})
</script>

<style lang="less">
.recommend-tabbar {
  display: flex;
  margin-bottom: 30px;
  .recommend-class {
    display: flex;
    align-items: center;
    .recommend-text {
      min-width: 93px;
      padding: 0 4px;
      height: 40px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active {
        background: #fadc54;
      }
      .class-title {
        font-size: 14px;
        font-weight: 600;
        color: #111111;
        margin-bottom: 6px;
        white-space: nowrap;
      }
      .class-subtitle {
        font-size: 12px;
        font-weight: 300;
        color: #111111;
      }
    }
    .split-line {
      margin: 0 28px;
    }
  }
}
</style>