<template>
  <div class="container">
    <div class="banner">

    </div>
    <AppTabbar :tabList="['广场', '视频', '主题']"
               v-model:curIndex="curIndex" />

    <div class="theme-bar"
         v-show="curIndex === 2">
      <ul class="theme-list">
        <template v-for="(item, index) in themeList"
                  :key="index">
          <li v-if="index < 6"
              @click="checkTheme(item,index)"
              :class="{themeact:index === themeIndex}">
            <img class="img"
                 v-lazy="item.bannerPic"
                 alt="">
          </li>
        </template>
      </ul>
    </div>
    <div class="container scroll"
         style="overflow-x:scroll;margin-bottom:10px"
         v-if="curIndex === 2 && subThemeList.length">
      <AppTabbar :tabList="subThemeList"
                 v-model:curIndex="subcurIndex">
        <template v-slot:default="slotProps">
          <div class="recommend-text">
            <div class="class-title"> {{ slotProps.item.timelineName }}
            </div>
          </div>
        </template>
      </AppTabbar>
    </div>

    <div class="cultural-list">
      <div class="list-warp">
        <template v-if="list.length">
          <div class="list-item"
               v-for="item in list"
               :key="item.id"
               @click="toDetail(item)">
            <img class="img"
                 v-lazy="item.type === 1 ? item.thumbnail:    item.videoCover ">
            <p class="name ellipsis-2">{{ item.title }}</p>

            <div class="author-bar">

              <img class="author-avar"
                   v-lazy="item.authorLogo || 'https://qdz.hsz88.com/static/logo-two.png'" />
              <span class="author-name ellipsis">{{item.authorName || "一县一特"}}</span>
              <i class="view-icon iconfont-p icon-eye"></i>
              <span class="view-num">{{item.viewNumber}}</span>
            </div>
          </div>
        </template>
        <qdzEmpty v-else
                  state="notData" />
      </div>
    </div>
    <div>
    </div>
    <qdzPagination v-if="list.length && refresh "
                   :total="totalCount"
                   :pageSize="pageSize"
                   :currentPage="pageNo"
                   @handleCheckPage="handleCheckPage" />
  </div>
</template>


<script >
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'culturalCenter'
})
</script>
<script setup>
import { ref, watch, reactive, toRefs, onMounted } from 'vue'
import { getCultureList, getCultureVideosList, selectThemeContentsByTimeLineId, contentsNew } from '@/api/culturalCenter'
import { getCultureListInfo, } from '@/api/home'
import { useRouter, useRoute } from 'vue-router'
import { queryUrlParmas } from '@/utils/utils'
import AppTabbar from '@/components/app-tabbar.vue'


const router = useRouter()
const route = useRoute()
const {
  list,
  themeList,
  totalCount,
  totalPage,
  pageSize,
  currentPage,
  pageNo,
  refresh,
  themeIndex,
  subThemeList,
  curIndex,
  subcurIndex,
  timeLineId,
  pageNum
} = toRefs(reactive({
  list: [],
  themeList: [],
  totalCount: 1,
  totalPage: 1,
  pageSize: 10,
  currentPage: 1,
  pageNo: 1,
  refresh: true,
  themeIndex: 0,
  subThemeList: [],
  curIndex: 0,
  subcurIndex: 0,
  timeLineId: '',
  pageNum: 0,
}))

const getList = async () => {
  const res = await getCultureList(
    {
      userId: '', cityId: '', columnId: 2, pageNum: pageNo.value, pageSize: 10
    }
  )
  if (res.code === '10000') {
    list.value = res.data.contentPage.list
    currentPage.value = res.data.contentPage.pageNum
    totalCount.value = res.data.contentPage.total
    refresh.value = true
  }
}
const getVideoList = async () => {
  const res = await getCultureVideosList(
    {
      cityId: '',
      type: 2,
      pageNum: pageNo.value,
      pageSize: 10
    }
  )
  if (res.code === '10000') {
    list.value = res.data.contentPage.list
    currentPage.value = res.data.contentPage.pageNum
    totalCount.value = res.data.contentPage.total
    refresh.value = true
  }
}
// 获取主题下的子分类
const getContentsNew = async (id) => {
  const res = await contentsNew({
    themeId: id,
  })
  if (res.code == '10000') {
    subThemeList.value = res.data.list
    timeLineId.value = subThemeList.value[0].id
    // 子分类下查第一个列表
    getByTimeLineId({
      pageNum: 1,
      pageSize: 10,
      timeLineId: timeLineId.value,
    })
  }
}
const getThemeList = async () => {
  const res = await getCultureListInfo({
    currentPage: 1,
    pageSize: 6
  })
  themeList.value = res.data.assistantImage
  let query = queryUrlParmas(themeList.value[0].url)
  getContentsNew(query.themeId)
}
const getByTimeLineId = async (data) => {
  const res = await selectThemeContentsByTimeLineId(data)
  list.value = res.data.list
  currentPage.value = res.data.pageNum
  pageNum.value = res.data.pageNum
  totalCount.value = res.data.total
  refresh.value = true
}

// 切换分页
const handleCheckPage = (currentPage) => {
  pageNo.value = currentPage
  if (curIndex.value === 0) getList()
  if (curIndex.value === 1) getVideoList()
  if (curIndex.value === 2) getByTimeLineId({
    pageNum: currentPage,
    pageSize: 10,
    timeLineId: timeLineId.value
  })
}
// 查看详情
const toDetail = (item) => {
  let toPath = router.resolve('/culturalCenter/detail?contentId=' + item.id)
  window.open(toPath.href, '_blank')
}
// 切换主题
const checkTheme = (item, index) => {
  themeIndex.value = index
  pageNo.value = 1
  refresh.value = false
  subcurIndex.value = 0
  let query = queryUrlParmas(item.url)
  getContentsNew(query.themeId)
}

// 

watch(() => route.query, (newV, oldV) => {
  // console.log(newV.curIndex)
  if (newV.curIndex) curIndex.value = Number(newV.curIndex)
  if (newV.themeIndex) themeIndex.value = Number(newV.themeIndex)
}, { immediate: true })

watch(() => curIndex.value, (nval, oldVal) => {
  if (nval != oldVal) {
    refresh.value = false
    pageNo.value = 1
  }
  if (curIndex.value === 0) getList()
  if (curIndex.value === 1) getVideoList()
  if (curIndex.value === 2) getThemeList()
}, { immediate: true })


//切换子分类
watch(() => subcurIndex.value, (nval, oldVal) => {
  if (nval != oldVal) {
    refresh.value = false
    pageNo.value = 1
  }
  timeLineId.value = subThemeList.value[subcurIndex.value].id
  getByTimeLineId({
    pageNum: 1,
    pageSize: 10,
    timeLineId: timeLineId.value,
  }, { immediate: true })

})

</script>

<style scoped lang="less">
.banner {
  height: 485px;
  background: #efe9dc;
  background-image: url("~@/assets/images/cultural/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 29px;
}
.theme-bar {
  width: 1240px;
  height: 224px;
  // background-color: green;
  background-image: url(~@/assets/images/cultural/themen.png);
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 94px;
  .theme-list {
    display: flex;
    width: 100%;
    height: 100px;
    padding: 0 30px;
    position: absolute;
    bottom: -50px;
    .themeact {
      background-color: #fff;
      transform: scale(1.2);
      .img {
        transform: scale(0.8);
      }
    }
    li {
      width: 152px;
      height: 100px;
      margin-right: 53px;
      :last-child {
        margin-right: 0;
      }
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .hoverShadow();
    }
  }
}
.cultural-list {
  // height: 1000px;
  .list-warp {
    display: flex;
    flex-wrap: wrap;
  }
  .list-item {
    width: 240px;
    height: 333px;
    background: #fff;
    margin-bottom: 10px;
    margin-right: 10px;
    // transition: none !important;
    cursor: pointer;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .hoverShadow();

    .img {
      width: 202px;
      height: 167px;
      width: 240px;
      height: 217px;
      padding: 25px 19px;
      background: #fff;
      object-fit: cover;
    }
    .name {
      width: 209px;
      height: 40px;
      line-height: 20px;
      overflow: hidden;
      margin: 14px auto;
      font-size: 14px;
      font-family: PingFang TC-Regular, PingFang TC;
      font-weight: 400;
      color: #5f6674;
    }

    .author-bar {
      padding: 0 16px;
      display: flex;
      align-items: center;
      .author-avar {
        width: 23px;
        height: 23px;
        background: #d6b177;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 7px;
      }

      .author-name {
        width: 108px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #bec3c9;
        margin-right: 14px;
      }
      .view-icon {
        width: 17px;
        margin-right: 4px;
        vertical-align: middle;
        font-size: 16px;
      }
      .view-num {
        width: 35px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        color: #dfe0e3;
      }
    }
  }
}
</style>