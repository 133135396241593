// 定义需要的接口函数
import request from '@/utils/request'

/**
 * 文化馆-广场文章列表查询接口
 * @returns Promise
 */
export const getCultureList = (data) => {
  return request('/honghu-activity-service/activity/culture/listNew', 'get', data)
}

/**
 * 文化馆-视频列表查询接口
 * @returns Promise
 */
export const getCultureVideosList = (data) => {
  return request('/honghu-activity-service/activity/culture/videoList', 'get', data)
}

/**
 * 文化馆 - 主题列表
 * @returns Promise
 */
export const getCultureThemeList = (data) => {
  return request('/honghu-activity-service/activity/culture/theme/listNew', 'get', data)
}

/**
 * 文化馆 - 主题馆根据导航选择列表
 * @returns Promise
 */
export const selectThemeContentsByTimeLineId = (data) => {
  return request('/honghu-activity-service/activity/culture/theme/selectThemeContentsByTimeLineId', 'get', data)
}

/**
 * 文化馆 - 文章详情
 * @returns Promise
 */
export const getCultureContentDetail = (data) => {
  return request('/honghu-activity-service/activity/culture/content/detail', 'get', data)
}

/**
 * 文化馆 - 查看文章评论列表
 * @returns Promise
 */
export const getCultureTopicComments = (data) => {
  return request('/honghu-activity-service/activity/culture/topic/getComments', 'get', data)
}

/**
 * 文化馆 - 获取文章评论 - 子评论
 * @returns Promise
 */
export const getCultureReplayComments = (data) => {
  return request('/honghu-activity-service/activity/culture/replay/comments', 'get', data)
}

/**
 * 文化馆 - 个人中心统计
 * @returns Promise
 */
export const getCultureUserStatisticsPc = (data) => {
  return request('/honghu-activity-service/culture/getCultureUserStatisticsPc', 'get', data)
}
/**
 * 文化馆 - 个人中心统计列表
 * @returns Promise
 */
export const getUserCultureListPc = (data) => {
  return request('honghu-activity-service/culture/getUserCultureListPc', 'get', data)
}

/**
 * 文化馆 - 主题
 * @returns Promise
 */
export const contentsNew = (data) => {
  return request('/honghu-activity-service/activity/culture/theme/contentsNew', 'get', data)
}
